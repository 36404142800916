import React, { useEffect, useState } from "react";
import '../ReceiveRequest/ReceiveRequest.css'; // Add your custom styles here
import Header from '../../components/Headermlm/Headermlm';
import axios from "axios";

const ReceiveRequestComponent = () => {
  // const data = [
  //   {
  //     id: 1,
  //     receiveRequest: 'Request A',
  //     date: '2024-08-26',
  //     name: 'John Doe',
  //     userId: '12345',
  //     amount: '500',
  //     status: 'Accepted',
  //     phoneNumber: '9876543210',
  //   },
  //   {
  //     id: 2,
  //     receiveRequest: 'Request B',
  //     date: '2024-08-26',
  //     name: 'Jane Smith',
  //     userId: '67890',
  //     amount: '500',
  //     status: 'Pending',
  //     phoneNumber: '0123456789',
  //   },
  // ];

  const [listreq, setlistreq] = useState([]);

  useEffect(() => {
    Requestlist();
  }, []);
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  const Requestlist = async () => {
    try {
      console.log(user_id,"user_id");

      // if (user_token && user_id) {
      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/receive/request/list", {},
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );

      setlistreq(response.data.data);
      // } else {
      //   console.log("User not authenticated.");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />

      <div className="table-container">
        <h1 className="heading">Receive Request</h1>

        <div className="table-responsive">
          <table className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Ref ID</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {listreq?.length > 0 ? (


                listreq.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.u_name}</td>
                    <td>{item.u_referal_id}</td>
                    <td>{500}</td>
                    <td>
                      <button
                        className={`status-button ${item.lr_status === "approved" ? "completed" : "pending"
                          }`}
                      >
                        {item.lr_status === "approved" ? "completed" : "pending"}
                      </button>
                    </td>
                    <td>{item.u_mobile}</td>
                  </tr>
                ))
              ) : (<p className="mt-5 ">No Request Available</p>)}

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReceiveRequestComponent;
