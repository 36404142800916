import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Register from "./pages/Register/Register";
import Dailyincome from "./pages/Dailyincome/Daily_income";
import Login from "./pages/Login/Login";
import Myincome from "./pages/Myincome/Myincome";
import Myprofile from "./pages/Myprofile/Myprofile";
import Myrank from "./pages/Myrank/Myrank";
import Myteam from "./pages/Myteam/Myteam";
import Home from "./pages/Home/Home";
import Postlogin from "./pages/PostLogin/Postlogin";
import Forgot from "./pages/ForgotPassword/forgot";
import Otp from "./components/OTP/Otp";
import Newpassword from "./components/NewPassword/NewPassword";
import Logotp from "./components/logotp/logotp";
import About from "./pages/About/About";
import Privacy from "./pages/privacy/Privacy";
import Profile from "./pages/Profile/Profile";
import Auth from "./context/Auth";
import Products from "./pages/Myproducts/Myproducts";
import Achivers from "./pages/Achivers/Achivers";
import LevelRequest from "./pages/LevelRequest/LevelRequest";
import LevelupPage from "./pages/LevelupPage/LevelupPage";
import Ourmission from "./pages/About/Ourmission";
import Landing from "./Landing";
import './Styles/globals.css';  
import './Styles/homepage.css';  
import './Styles/timeline.css';  
import './Styles/responsive.css';
import './Styles/multiRangeSlider.css'; 
import './Styles/elementor.css'; 
import './Styles/formandpopup.css'; 
import SelfHealthManagementPlatform from "./pages/Services/selfhealth";
import AshtangaYogaMeditation from "./pages/Services/asthanga";
import MartialArts from "./pages/Services/martial";
import DmitFingerprintsAnalysis from "./pages/Services/dmit";
import AgriOrganic from "./pages/Services/agri";
import ECommerce from "./pages/Services/ecommerce";
import NotFound from "./pages/Error/Error";
import Dashboard from "./pages/dashboard/dashboard";
import TableComponent from "./pages/Giverequest/Giverequest";
import ReceiveRequestComponent from "./pages/ReceiveRequest/ReceiveRequest";
import ReferencePage from "./pages/ReferencePage/ReferencePage";
import ReferencePageone from "./pages/ReferencePage/ReferencePage";
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import CardUI from "./pages/Previliagecard/Previliagecard";
import UniversalBasicIncome from "./pages/Services/UniversalBasicIncome";
import CardUIpri from "./pages/Services/Privilage";
import Termsandcondition from "./pages/Tremsandcondition/Termsandcondition";
import Changepassword from "./Changepassword/Changepassword";



function App() {
  return (
    <>
      <userContext></userContext>
      <BrowserRouter>
        <Auth>
        
          <Routes>
            <Route path="/Login" element={<Login />} />
            <Route path="/change-password" element={<Changepassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/income" element={<Dailyincome />} />
            <Route path="/my_income" element={<Myincome />} />
            <Route path="/my_profile" element={<Myprofile />} />
            <Route path="/my_rank" element={<Myrank />} />
            <Route path="/my_team" element={<Myteam />} />
            <Route path="/home" element={<Home />} />
            <Route path="/post_login" element={<Postlogin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/logotp" element={<Logotp />} />
            <Route path="/new_password" element={<Newpassword />} />
            <Route path="/service/SelfHealthManagementPlatform" element={<SelfHealthManagementPlatform/>} />

            <Route path="/service/AshtangaYogaMeditation" element={<AshtangaYogaMeditation/>} />

            <Route path="/service/MartialArts" element={<MartialArts/>} />

            <Route path="/service/DmitFingerprintsAnalysis" element={<DmitFingerprintsAnalysis/>} />

            <Route path="/service/AgriOrganic" element={<AgriOrganic/>} />

            <Route path="/service/ECommerce" element={<ECommerce/>} />


            <Route path="/ourmission" element={<Ourmission />} />
            <Route path="/About" element={<About />} />

            <Route path="/Privacypolicy" element={<Privacy />} />
            <Route path="/TermsandConditionsforConsumers" element={<Termsandcondition />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-products" element={<Products />} />
            <Route path="/achivers" element={<Achivers />} />

            <Route path="/LevelupPage" element={<LevelupPage />} />

            <Route path="/LevelRequest" element={<LevelRequest />} />

            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/" element={<Landing />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/Giverequest" element={<TableComponent />} />
            <Route path="/ReceiveRequest" element={<ReceiveRequestComponent />} />
            <Route path="/references" element={<ReferencePageone/>} />
            <Route path="/Notification" element={<NotificationPage/>} />
            <Route path="/PreviliageCard" element={<CardUI/>} />
            <Route path="/service/UniversalBasicIncome" element={<UniversalBasicIncome/>} />
            <Route path="/service/PreviliageCard" element={<CardUIpri/>} />
            
          </Routes>
        </Auth>
      </BrowserRouter>
    </>
  );
}

export default App;
