import React, { useState, useContext, useEffect } from "react";
import "./Ourmission.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/Auth";
import Logo from "../images/main_logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const About = () => {
  
  

  
 
  return (
    <>
      <ToastContainer />
      <div className="container-fluid homereq">
        <div className="home-detailsreq">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>

         
         

        
          <div>
            <h2 className="text-center headcolor text-capitalize">About us</h2>
            <p className="text-center">
              {" "}
              The world is on a relentless journey towards progress, with
              innovations and technological advances (AI etc.) rapidly
              transforming our quality of life. However, amid this surge of
              progress, a vast majority of people are left without the means to
              meet even the basic standards of living or enjoy real freedom.
              Poverty, unemployment, skill development, productivity, monetary
              control, education system, information technology, women's
              empowerment, manpower management, revenue management, inflation,
              and lack of cooperation and friendship, these critical factors
              pose major challenges in the world system. Here,c888.in plateform
              emerges as a permanent solution for this.We are committed to
              empowering individuals healthily, intellectually and financially
              through our diverse projects and leading communities
              towards prosperity.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
