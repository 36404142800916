// src/components/ScrollArrow.js

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const ScrollArrow = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = (scrollTop / docHeight) * 100;
    setScrollProgress(scrollPercentage);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="scroll-arrow-container" onClick={scrollToTop}>
      <div
        className="scroll-arrow"
        style={{ background: `conic-gradient(#00f ${scrollProgress}%, #ccc ${scrollProgress}%)` }}
      >
      <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </div>
  );
};

export default ScrollArrow;
