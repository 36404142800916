import React from 'react';
import './Services.css'; // Import your custom styles

import universalBasicIncome from '../images/human.jpg'; // Update with the correct image path

const UniversalBasicIncome = () => {
  return (
    <div className="universal-basic-income container">
      <header className="headerser">
        <h1>Universal Basic Income</h1>
      </header>
      
      <section>
        <img src={universalBasicIncome} alt="Universal Basic Income" className='imageheight'/>
      </section>
      
      <div className='mt-4'>
        <p>
          Universal Basic Income (UBI) is a financial model designed to provide all citizens with a fixed income, regardless of their employment status. It aims to reduce poverty and inequality by ensuring that everyone has access to a basic standard of living.
        </p>
        <p>
          Our platform educates individuals on the principles of UBI, its potential economic and social impacts, and how it could transform societies by providing financial security and encouraging economic participation.
        </p>
      </div>

      <section className="additional-info">
        <h2>Key Features:</h2>
        <ul>
          <li>Comprehensive UBI education and resources</li>
          <li>Analysis of economic impacts</li>
          <li>Case studies of UBI implementations</li>
          <li>Tools for financial planning under UBI</li>
        </ul>
      </section>
    </div>
  );
}

export default UniversalBasicIncome;
