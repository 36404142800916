import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';

import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';




// import axios from 'axios';

const Changepassword = () => {
  const navigate = useNavigate();
  // const deviceId = uuidv4();
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: ""
  });

  const checkpassword = () => {
    if (formData.password !== formData.confirmpassword) {
      return false
    } else {
      return true
    }
  };



  const notify = (message) => {
    toast(message);
  };

  const loginSubmit = async (e) => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    e.preventDefault();
    try {
      let check = checkpassword()
      if (check == true) {
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/user/changepassword",
          formData, {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          }
        }
        );



        console.log(response.data.user_id, response.data.user_token, "response.data.user_token");


        if (response.data.result === true) {
          notify(response.data.message);
          navigate('/dashboard')
        } else {
          notify(response.data.message);
        }
      } else {
        toast.error("Password must be same");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };


  return (

    <>


      <div className="container-fluid logins">
        <form onSubmit={loginSubmit}>
          <h1 className='text-center'>Change Password </h1>

          <div className="log-inputs">
            <div className="password">
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                type='password'
                id="password"
                name="password"
                onChange={onHandleChange}

                placeholder="Enter your password"
              />

            </div>
          </div>
          <div className="log-inputs">
            <div className="password">
              <Form.Label htmlFor="password">Confirm Password</Form.Label>
              <Form.Control
                type='password'
                id="confirmpassword"
                name="password"
                onChange={onHandleChange}

                placeholder="Enter your password"
              />

            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default Changepassword;
