import React from "react";
import Header from "../../components/Headermlm/Headermlm";
import logo from "../../pages/images/main_logo.png";
import profile from "../../pages/images/profile.png";

const CardUIpri = () => {
  return (
    <>
      <header className="headerser">
        <h1>Privilege Card</h1>
      </header>
      <div style={styles.outerContainer} className="m-1">
        <div style={styles.cardContainer}>
          <div style={styles.chipContainer}>
            <img
              src={logo} // Placeholder image for chip
              alt="Chip"
              style={styles.chip}
            />
          </div>
          <div style={styles.cardNumber}>
            <h2>1003851</h2>
          </div>
          <div style={styles.cardDetails}>
            <div>
              <p style={styles.label}>Cardholder Name</p>
              <h5>C888</h5>
            </div>
            <div>
              <p style={styles.label}>Valid To</p>
              <h4>08/24</h4>
            </div>
          </div>
          <div style={styles.profileContainer}>
            <img
              src={profile} // Placeholder image for profile
              alt="Profile"
              style={styles.profilePic}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    backgroundColor: "#f0f0f0", // Optional background color for contrast
  },
  cardContainer: {
    backgroundColor: "#31588a", // Dark blue background
    width: "100%",
    maxWidth: "400px",
    height: "220px",
    borderRadius: "20px",
    padding: "20px",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box",
  },
  chipContainer: {
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  chip: {
    width: "70px",
    height: "70px",
    borderRadius: "4px",
  },
  cardNumber: {
    marginTop: "60px",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "2px",
  },
  cardDetails: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    padding: "0 10px",
  },
  label: {
    fontSize: "12px",
    marginBottom: "5px",
    textTransform: "uppercase",
    opacity: 0.8,
  },
  profileContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  profilePic: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },
  "@media (max-width: 480px)": {
    cardContainer: {
      padding: "15px",
      height: "200px",
    },
    chip: {
      width: "30px",
      height: "20px",
    },
    cardNumber: {
      fontSize: "20px",
      marginTop: "50px",
    },
    cardDetails: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "15px",
      padding: "0",
    },
    label: {
      fontSize: "10px",
    },
    profileContainer: {
      width: "50px",
      height: "50px",
    },
  },
};

export default CardUIpri;
