import React from "react";
import { useLocation } from "react-router-dom";

import logo from "../../pages/images/main_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const location = useLocation();
  const currentPath = location.pathname;

 

  return (
    <nav
      className="head-footer-bg  heighthead sticky-header navbar navbar-expand-lg"
      id="customnavbar"
    >
      <div className="container fullwidth">
        <a href="/" className="navbar-brand">
          <img
            src={logo}
            width="80"
            height="80"
            alt="logo"
            className="logocontain"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
        <FontAwesomeIcon icon={faBars} className="ham"  />
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto  ">
            {" "}
            {/* Align items to the right */}
            <li className="nav-item me-4">
              <a
                className={`nav-a ${currentPath === "/" ? "active" : ""}`}
                href="/"
              >
                HOME
              </a>
            </li>
            <li className="nav-item dropdown me-4">
              <a
                className="nav-a dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                OUR SERVICES
              </a>
              <ul className="dropdown-menu">
              <li>
                  <a className="dropdown-item" href="/service/UniversalBasicIncome">
                    Universal Basic Income
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/SelfHealthManagementPlatform">
                    Self Health Management Platform
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/AshtangaYogaMeditation">
                    ASHTANGA Yoga Meditaion
                  </a>
                </li>
{/**<li>
                  <a className="dropdown-item" href="/service/MartialArts">
                    Martial arts.Wushu, Taichi
                  </a>
                </li> */}
                
                <li>
                  <a className="dropdown-item" href="/service/DmitFingerprintsAnalysis">
                    DMIT fingerprints Analysis
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/AgriOrganic">
                    Agri Organic
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service/ECommerce">
                    E-Commerce
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/service/PreviliageCard">
                  Privilege Card
                  </a>
                </li>
              </ul>
            </li>


            <li className="nav-item">
            <a
              className={`nav-a ${
                currentPath === "/contact-us" ? "active" : ""
              }`}
              href="/#contactus"
            >
              CONTACT US
            </a>
          </li>
          <a
          className={`nav-a ${
            currentPath === "/Aviation" ? "active" : ""
          }`}
          href="/Login"
        >
            <div className="btnconnect ">
            <li className="nav-item me-4">
             
          Connect to Community
           
            </li>
            </div>
            </a>
         
          </ul>
        </div>
      </div>
    </nav>
  );
}
