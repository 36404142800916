import React, { useState, useContext, useEffect } from "react";
import "./Home.scss";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import { userContext } from "../../context/Auth";
import Logo from "../images/main_logo.png";
import Headermlm from '../../components/Headermlm/Headermlm';
const Home = () => {
  const { user } = useContext(userContext);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(
    location.state?.data || JSON.parse(localStorage.getItem("data")) || {}
  );
  const [userStatus, setUserStatus] = useState(
    localStorage.getItem("userStatus") || "Inactive"
  );

  useEffect(() => {
    if (location.state?.data) {
      localStorage.setItem("data", JSON.stringify(location.state.data));
      setData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (user?.data?.user_status) {
      localStorage.setItem("userStatus", user.data.user_status);
      setUserStatus(user.data.user_status);
    }
  }, [user]);

  const getReferralId = () => {
    setShowMessage(true);
  };

  const checkStatus = async () => {
 
      navigate("/my_profile");
   
  }

  const moveToMyTeam = () => {
    navigate("/my_team", { state: { data: data } });
  };

  const LevelRequest = () => {
    navigate("/LevelRequest", { state: { data: data } });
  };

  const moveToIncome = () => {
    navigate("/my_income", {
      state: { data: data, API: data.user_referral_id },
    });
  };

  const LevelupPage = () => {
    navigate("/LevelupPage", {
      state: { data: data, API: data.user_referral_id },
    });
  };

  const Aboutus = () => {
    navigate("/about");
  };

  const moveToRank = () => {
    navigate("/my_rank", { state: { data: data, userRank: user } });
  };

  return (
    <>
    <Headermlm/>
   
    <div className="container-fluid home">
      <div className="home-details">
        <div className="logo">
          
        </div>

        <div className="profile-links">
          <div className="social" onClick={getReferralId}>
            Referral Link
          </div>
          <div className="social" onClick={checkStatus}>
            My Profile
          </div>
          <div className="social" onClick={moveToMyTeam}>
            My Team
          </div>
          <div className="social" onClick={LevelupPage}>
            Level Up Page <span className="badge bg-black">67</span>
          </div>
          <div className="social" onClick={LevelRequest}>
            Request <span className="badge bg-black">67</span>
          </div>
          <div className="social" onClick={moveToRank}>
            My Rank
          </div>
          <NavLink to="/about">
            <div className="social">About Us</div>
          </NavLink>
          <NavLink to="/ourmission">
            <div className="social">Our Mission</div>
          </NavLink>
        </div>
      </div>

      {showMessage && (
        <div className="success-message">
          <CiCircleRemove
            size={30}
            color="black"
            className="close-icon"
            onClick={() => setShowMessage(false)}
            style={{ position: "absolute", right: "20px", top: "5px" }}
          />
          <h6>
            https://www.jle.org.in/register/?referral_id=
            {data.user_referral_id}
          </h6>
        </div>
      )}
    </div>
     </>
  );
};

export default Home;
