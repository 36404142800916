import React, { useEffect, useState } from "react";
import "./Myprofile.scss";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imagedemo from "../images/404.jpg";

const Myprofile = () => {
  const [userData, setUserData] = useState({
    u_name: "",
    u_gender: "",
    u_email: "",
    u_mobile: "",
    u_payment_type: "",
    u_state: "",
    u_district: "",
    u_address: "",
    u_referal_id: "",
    u_profile_pic: "",
    bank: [
      {
        b_name: "",
        acc_name: "",
        acc_number: "",
        ifsc_code: "",
        br_name: "",
      },
    ],
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const user_token = localStorage.getItem("user_token");

    axios
      .post(
        "https://lunarsenterprises.com:6001/e888/user/view",
        { user_id: user_id },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      )
      .then((response) => {
        const user = response.data.data[0];
        setUserData(user);
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        console.error(error);
      });
  }, [user_id]);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setUserData((prevData) => ({
    //     ...prevData,
    //     u_profile_pic: reader.result,
    //   }));
    // };
    // reader.readAsDataURL(file);
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("user_token");
    const userId = localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("u_id", userId);
    formData.append("u_name", userData.u_name);
    formData.append("u_gender", userData.u_gender);
    formData.append("u_email", userData.u_email);
    formData.append("u_mobile", userData.u_mobile);
    formData.append("image", selectedImage);
    formData.append("u_payment_type", userData.u_payment_type);
    formData.append("u_state", userData.u_state);
    formData.append("u_district", userData.u_district);
    formData.append("u_address", userData.u_address);
    formData.append("u_referal_id", userData.u_referal_id);
    formData.append("b_name", userData.bank[0]?.b_name);
    formData.append("acc_name", userData.bank[0]?.acc_name);
    formData.append("acc_number", userData.bank[0]?.acc_number);
    formData.append("ifsc_code", userData.bank[0]?.ifsc_code);
    formData.append("br_name", userData.bank[0]?.br_name);

    axios
      .post("https://lunarsenterprises.com:6001/e888/user/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          user_id: userId,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("Profile updated successfully!");
        window.location.reload()
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile");
      });
  };

  if (!userData.u_name) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid pro">
      <form onSubmit={handleSubmit}>
        <h4 className="mb-4">My Profile</h4>

        <div className="pro-imag">
          <div className="image-holder">
            <img
              src={
                userData.u_profile_pic
                  ? `https://lunarsenterprises.com:6001/${userData.u_profile_pic}`
                  : imagedemo
              }
              alt="Profile"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </div>
          <Form.Control
            className="control mt-2 mb-2"
            type="file"
            name="image"
            onChange={handleImage}
          />
        </div>

        <Form.Label htmlFor="u_name" className="mb-2">
          Name
        </Form.Label>
        <Form.Control
          type="text"
          id="u_name"
          value={userData.u_name}
          onChange={onHandleChange}
          readOnly
        />

        <Form.Label htmlFor="u_gender" className="mb-2 mt-2">
          Gender
        </Form.Label>
        <Form.Control
          type="text"
          id="u_gender"
          value={userData.u_gender}
          onChange={onHandleChange}
          readOnly
        />

        <Form.Label htmlFor="u_email" className="mb-2 mt-2">
          Email
        </Form.Label>
        <Form.Control
          type="email"
          id="u_email"
          value={userData.u_email}
          onChange={onHandleChange}
          readOnly
        />

        <Form.Label htmlFor="u_mobile" className="mb-2 mt-2">
          Contact number
        </Form.Label>
        <Form.Control
          type="text"
          id="u_mobile"
          value={userData.u_mobile}
          onChange={onHandleChange}
          readOnly
        />

        <Form.Label htmlFor="u_payment_type" className="mb-2 mt-2">
          Payment Method
        </Form.Label>
        <Form.Control
          type="text"
          id="u_payment_type"
          value={userData.u_payment_type}
          onChange={onHandleChange}
        />

        <Form.Label htmlFor="u_state" className="mb-2 mt-2">
          State
        </Form.Label>
        <Form.Control
          type="text"
          id="u_state"
          value={userData.u_state}
          onChange={onHandleChange}
        />

        <Form.Label htmlFor="u_district" className="mb-2 mt-2">
          District
        </Form.Label>
        <Form.Control
          type="text"
          id="u_district"
          value={userData.u_district}
          onChange={onHandleChange}
        />

        <Form.Label htmlFor="u_address" className="mb-2 mt-2">
          Address
        </Form.Label>
        <Form.Control
          type="text"
          id="u_address"
          value={userData.u_address}
          onChange={onHandleChange}
        />

        <Form.Label htmlFor="u_referal_id" className="mb-2 mt-2">
          Connect Id
        </Form.Label>
        <Form.Control
          type="text"
          id="u_referal_id"
          value={userData.u_referal_id}
          onChange={onHandleChange}
        />

        <h4 style={{ marginTop: "20px" }}>Add Account</h4>

        <Form.Label htmlFor="b_name">Bank Name</Form.Label>
        <Form.Control
          type="text"
          id="b_name"
          value={userData.bank[0]?.b_name}
          onChange={(e) => {
            const { value } = e.target;
            setUserData((prevData) => ({
              ...prevData,
              bank: [{ ...prevData.bank[0], b_name: value }],
            }));
          }}
        />
        
        <Form.Label htmlFor="acc_name" className="mb-2 mt-2">
          Account Name
        </Form.Label>
        <Form.Control
          type="text"
          id="acc_name"
          value={userData.bank[0]?.acc_name}
          onChange={(e) => {
            const { value } = e.target;
            setUserData((prevData) => ({
              ...prevData,
              bank: [{ ...prevData.bank[0], acc_name: value }],
            }));
          }}
        />
        
        <Form.Label htmlFor="acc_number" className="mb-2 mt-2">
          Account Number
        </Form.Label>
        <Form.Control
          type="text"
          id="acc_number"
          value={userData.bank[0]?.acc_number}
          onChange={(e) => {
            const { value } = e.target;
            setUserData((prevData) => ({
              ...prevData,
              bank: [{ ...prevData.bank[0], acc_number: value }],
            }));
          }}
        />
        
        <Form.Label htmlFor="ifsc_code" className="mb-2 mt-2">
          IFSC Code
        </Form.Label>
        <Form.Control
          type="text"
          id="ifsc_code"
          value={userData.bank[0]?.ifsc_code}
          onChange={(e) => {
            const { value } = e.target;
            setUserData((prevData) => ({
              ...prevData,
              bank: [{ ...prevData.bank[0], ifsc_code: value }],
            }));
          }}
        />
        
        <Form.Label htmlFor="br_name" className="mb-2 mt-2">
          Branch Name
        </Form.Label>
        <Form.Control
          type="text"
          id="br_name"
          value={userData.bank[0]?.br_name || ""}
          onChange={(e) => {
            const { value } = e.target;
            setUserData((prevData) => ({
              ...prevData,
              bank: [{ ...prevData.bank[0], br_name: value }],
            }));
          }}
        />
        
        <button
          type="submit"
          className="btn btn-primary mt-3"
          style={{ width: "100%" }}
        >
          Save
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Myprofile;
