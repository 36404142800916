import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="container mt-5 login" id="about" style={{height:"unset"}}>
    <form>
    <h1 className="text-center mb-4">PRIVACY POLICY</h1>
   <p> 

   At e888. in online platform we are committed to protecting your privacy. Please read on for more details about our webite privacy policy.
    Controllers of personal information 
   Any personal information provided or to be gathered by e888. in online platform is controlled by The e888.in 
   What information do we collect?
   Information submitted to e888.in online platform is used by e888.in only and is used for its intended purpose.</p>
  </form>
    </div>
  );
};

export default Privacy;
