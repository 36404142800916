import React, { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const UserLevels = ({ user_id }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    const fetchUserData = async () => {
      try {
        const master = {
          user_id: user_id,
        };
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/user/view",
          master,
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id: user_id,
            },
          }
        );
        if (response.data && response.data.result) {
          setUserData(response.data.data[0]);
        } else {
          setError("User data not found");
        }
      } catch (err) {
        setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Calculate the total level progress as a percentage
  const totalLevels = 6;
  const activeLevels = [
    userData.u_level1,
    userData.u_level2,
    userData.u_level3,
    userData.u_level4,
    userData.u_level5,
    userData.u_level6,
  ].filter((level) => level === 1).length;
  const progressPercentage = (activeLevels / totalLevels) * 100;

  return (
    <div style={{ width: "150px", margin: "0 auto" }}>
     
    {/**
         <h3 style={{ textAlign: "center" }}>{userData.u_name}'s Progress</h3>
        <CircularProgressbar
        value={progressPercentage}
        text={`${Math.round(progressPercentage)}%`}
        styles={buildStyles({
          textSize: "16px",
          pathColor: "#4caf50", // Green for active
          textColor: "#4caf50",
          trailColor: "#e0e0e0",
          backgroundColor: "#f44336", // Red for non-active
        })}
      />
        */}  
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        Your  Levels: {activeLevels}/{totalLevels}
      </div>
    </div>
  );
};

export default UserLevels;
