import React from "react";


const Termsandcondition = () => {
  return (
    <div className="container login mt-5" id="about" style={{height:"unset"}}>
      <form>
        <h1 className="text-center">TERMS AND CONDITIONS</h1>
        <p>
        1. Cooperate or register with e888. in online platform with your full consent only after reading each and every Terms and Conditions of e888. in online platform very carefully, persuading yourself that you understand and agree.

        </p>
        <p>
        2. I agree solely responsible for any financial, social, psychological, or legal harm caused to me in the future as a result of my lack of personal knowledge about the Terms and Conditions of e888. in online platform, or as a result of having associated with the e888. in online platform by being intentionally or unintentionally misled by another consumer. I am self convinced and understand that e888. in online platform is not responsible in any way, and it is 100% my personal need and responsibility as a consumer to fully read and understand the following Terms and Conditions.

        </p>
        <p>
        3. I understand that Name, Email ID and Phone number should be updated at the time of registration in e888. in online platform, otherwise the consumership registration cannot be completed.

        </p>
        <p>
        4. A smart phone is must to use the e888. in online platform application .

        </p>
        <p>
       
5. With the conviction that it is my social responsibility to help those in financial need in the society, I am ready to provide unconditional direct financial help to the consumers who have registered and cooperated with e888. in online platform.
        </p>
        <p>6. I understand and agree that I am obligated to provide KYC information to e888. in online platform at the time of registration with the platform.
        </p>
        <p>
        7. I acknowledge that I am personally responsible for explaining precisely and clearly the concept of the e888. in online platform to others and associate more individuals who are in need of financial help and other services provided by the platform, and are willing to give direct financial help to others on the platform.

        </p>
        <p>
        8. I agree to provide unconditional direct financial help to consumers who have previously entered the e888. in online platform and provided unconditional direct financial help.

        </p>
        <p>9. I agree that I am personally ready and willing to provide unconditional direct financial help to 6 other consumers of e888. in online platform by paying Rs. 3,000/-(Rupees Three Thousand only). Rs. 3,000 is to be provided in the form of Rs. 500 × 6 consumers.</p>


        <p>
        10. I also understand that Rs. 3,000 that I give as financial help to 6 consumers of e888. in online platform must be paid in my interest, personally, unconditionally, voluntarily and directly as financial help will not seek reimbursement under any circumstances and I will not demand refund under any circumstances.
      </p>
      <p>
      11. I understand and accept that the transaction of Rs. 3,000 that I give as financial help to other consumers of e888. in online platform, should only be done using UPI(Google pay, phone pay, pay TM etc) which is a part of Digital India. Other modes of transactions such as DD, Cheque, NEFT, Cash deposit or Bank account transfer are not allowed and I shall be fully responsible for all the losses caused to me by the above unauthorized transactions
      </p>
      <p>
      12. I agree to make use of 10% of the money that I earn through e888. in online platform, for the unconditional direct financial help to the consumers of e888. in online platform.

      </p>
      <p>
      13. In accordance with the "Give help chart" I obtain from the platform, I must unconditionally and financially help six consumers using the e888. in online platform.
      Rs. 500 for each of
      six consumers
      </p>
      <p>
      14. I also fully understand and comprehend that the way which I will be receiving direct financial help as per the "Receive help chart"  is as follows;    Rs. 500 each from 62 consumers. i.e, Rs. 31,000.

      </p>
      <p>15. I here by declare that the money I use to financially help 6 other consumers of e888. in online community was earned through legally and not through terrorism, treason, extortion, theft or any other criminal or illegal activity.</p>
      <p>
      16. I clearly understand that the financial help provided unconditionally directly by me to the consumers on e888. in online platform is for the personal urgent needs and wants of the consumers.

      </p>
      <p>
      17. From the date of commencement of consumership on e888. in online platform, I am obliged to confirm the financial help received directly from other consumers, considering the time limit for receiving help and conformation is 48 hours on the respective days. I understood that if I fail to do so, I may hinder the smooth functioning of the platform and therefore the consumer who provides "Give help" may file a complaint against me with the platform based on evidence and the platform can take the necessary action.

      </p>
      <p>18. I fully understand and agree that misusing or misinterpreting the concept, honesty or transparency of e888. in online platform's Terms and Conditions, and using them to mislead the public is illegal, and e888. in online platform will persue legal action against those who do so.
      </p>





      <p>
      19. I am also aware that the password I use on the e888. in online platform is strictly personal and should not be shared with other consumers or individuals.

      </p>
      <p>
      20. I understand and agree that I will be solely liable for any damages or legal proceedings incurred by the platform or individuals if the password to be kept very private on the e888. in online platform, is disclosed to other consumers or individuals. I agree that I shall be solely responsible for all financial liability, damages and legal proceedings that may be incurred by me, the platform or any other person, and that the platform shall not be liable in anyway whatsoever for any financial lose caused by the access of this password to other persons.
      </p>
      <p>
      21. I understand that the e888. in online platform doesnot allow asking another consumer or person for the consumership password of any consumer on the plateform, nor does it authorise giving the password to the asked consumer or person in anyway or any reason.

      </p>
      <p>
      22. I acknowledge and agree that if e888. in online platform is convinced that any consumer of e888. in online platform is acting against the platform or against the society, or engaging in criminal activity, the platform have the authority to terminate the person's consumership and ban the person from the platform for life.

      </p>
      <p>
      23. I comprehensively understand and agree that, if the platform is covinced that I am promoting idea or business using the contact information of other consumers I got from e888. in online platform, the e888. in online platform has full authority to revoke my consumership and remove me from the platform without further notice.

      </p>
      <p>
      24. I personally hereby solemnly certify that I shall be solely responsible for all damages and legal proceedings that may arise if I act contrary to the Terms and Conditions and shall not involve e888. in online platform or any other consumers of the platform in any legal issues.

      </p>
      <p>
      25. I understand that if I fail to cooperate with the e888. in online platform, If I am not interested or if I voluntarily deactivate my consumership for any reason, I will not get refunded the amount of unconditional direct financial help or "Give help" that I have given to the consumers, and my deactivated account cannot be re-activated under any circumstances and can only create a new consumership account to cooperate with the platform
      </p>
      <p>
      26. I understand that as part of promoting the concept of digital India, the consumers of the platform shall provide and receive direct financial help only through UPI transaction and not through direct deposite, NEFT, Cheque, DD etc.

      </p>
      <p>27. To ensure(confirm) receipt of financial help received directly from other consumers from the date of commencement of consumership on e888. in online platform, knowing that if I intentionally neglect or fail to receive help within 2 days, it may cause obstacles for giving and receiving financial help and at times may threaten their life and livelihood. I hereby voluntarily certify, agree and grant e888. in online platform full authority to revoke or deactivate my consumership account.
</p>

      <p>
      28. e888. in online platform's official social media such as whatsapp, whatsapp group, youtube channel, Instagram page and telegram group created from the platform's official mobile numbers consist of the officials of the platform. The platform takes sole responsibility for the announcements, promotions and statements made in these official groups. e888. in online platform shall not be held responsible or liable in any way for any announcement, promotions or statements made on social media by other consumers, individuals, or those who spread slander against the platform.

    </p>
    <p>
    29. I acknowledge that it is my personal duty and responsibility to pay taxes and I do it inorder to support the growth of the Nation.

    </p>
    <p>
    30. I understand that e888. in online platform will personally take legal action against consumers or individuals who act against the concept, honesty, transparency and Terms and Conditions of e888. in online platform.
    </p>
   
    <p>
    31. I understand that, once a consumer registers on the platform and provides direct financial help to 6 other consumers, the platform provides the opportunity to receive direct financial help from other consumers. To achieve this, I am required to invite 2 new consumers to cooperate with the e888. in online platform using my invitation link, within 30 days.

    </p>
    <p>15. I here by declare that the money I use to financially help 6 other consumers of e888. in online community was earned through legally and not through terrorism, treason, extortion, theft or any other criminal or illegal activity.</p>
    <p>
    32. I consciously, personally consent to provide my personal information like my name, mail ID, mobile number, UPI id and consumer number to other consumers for receiving financial help directly from other consumers through e888. in online platform.
    </p>
    <p>
    33. e888. in online platform provides equal justice and rights to all individuals associated with e888. in online platform. And so, insulting, threatening, teasing, commanding, obscenities personally, socially, religiously, politically, economically, psychologically or legally against the persons on the e888. in online platform is a crime against the legal systems of India. It doesnot in anyway endorse or promote the platform. I understand and agree that if such activities are noticed, the persons doing it will be removed from the platform.

    </p>
    <p>

    34. If women associated with e888. in online platform are personally, socially insulted, humiliated or interfered with through online media, such acts are brought to the notice of e888. in online platform and will be forward to investigative agencies on the basis of evidence.I understand that e888. in online platform has the authority to take legal action in this regard.

    </p>
    <p>
    35. To improve the consumers' financial prosperity or for the betterment of the activities related to e888. in online platform relevant changes, amendments and additions are made to e888. in online platform rules of administration, Terms and Conditions, Guidlines and applications. I personally understand, accept and agree that e888. in online platform reserves absolute rights and I am bound by the same.

    
    </p>
    <h4>I have read, understood and agreed to all the Terms and Conditions listed above, including numbers 1 through 35, and I hereby sign and confirm my agreement.</h4>
      </form>
    </div>
  );
};

export default Termsandcondition;
