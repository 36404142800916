import React from 'react';
import './Services.css'; // Import your custom styles

import selfhealth from '../images/diet.jpg'

const SelfHealthManagementPlatform = () => {
  return (
    <div className="self-health-management container">
      <header className="headerser">
        <h1>Self Health Management Platform</h1>
      </header>
      
      <section className="">
      <img src={selfhealth} className='imageheight'/>
      </section>
      <div className='mt-4'>
      
      <p>
      Welcome to the Self Health Management Platform. Our platform empowers you to take control of your health by providing comprehensive tools and resources. Whether you're tracking your daily activities, managing your diet, or monitoring your mental health, we've got you covered. 
    </p>
    <p>
      Our platform offers personalized health recommendations, progress tracking, and easy integration with various health devices. Join us on the journey to better health and well-being.
    </p>
    </div>

      <section className="additional-info">
        <h2>Features:</h2>
        <ul>
          <li>Personalized health insights</li>
          <li>Activity and diet tracking</li>
          <li>Mental health resources</li>
          <li>Integration with health devices</li>
        </ul>
      </section>
    </div>
  );
}

export default SelfHealthManagementPlatform;
