import React, { useState, useContext, useEffect } from "react";
import "./Ourmission.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/Auth";
import Logo from "../images/main_logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Ourmission = () => {
  const { user } = useContext(userContext);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(
    location.state?.data || JSON.parse(localStorage.getItem("data")) || {}
  );
  const [userStatus, setUserStatus] = useState(
    localStorage.getItem("userStatus") || "Inactive"
  );
  const [listreq, setlistreq] = useState([]);

  useEffect(() => {
    if (location.state?.data) {
      localStorage.setItem("data", JSON.stringify(location.state.data));
      setData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (user?.data?.user_status) {
      localStorage.setItem("userStatus", user.data.user_status);
      setUserStatus(user.data.user_status);
    }
  }, [user]);

  useEffect(() => {
    Requestlist();
  }, []);

  const Requestlist = async () => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      if (user_token && user_id) {
        const response = await axios.get(
          "https://lunarsenterprises.com:6001/e888/user/levelup/request/list",
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id: user_id,
            },
          }
        );

        // const filteredData = response.data.data.filter(
        //   (item) => item.lr_status === "active"
        // );

        setlistreq(response.data.data);
      } else {
        console.log("User not authenticated.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkStatus = async (lr_id) => {
    try {
      const params = {
        lr_id: [lr_id], // Pass the selected lr_id as an array
      };
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/levelup/request/approve",
        params,
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );
      const { message } = response.data;
      toast.success(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid homereq">
        <div className="home-detailsreq">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>

          {listreq?.map((item) => (
            <div className="profile-links mb-4" key={item.lr_id}>
              <div className="socialreq">
                <div className="logoreq">
                  <img
                    src={
                      item.u_profile_pic
                        ? `https://lunarsenterprises.com:6001/${item.u_profile_pic}`
                        : Logo
                    }
                    alt=""
                    className="pic"
                  />
                  <div>{item.u_name}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <div
                    className={`p-2 " ${
                      item.lr_status === "approved"
                        ? "buttonstyle2"
                        : "buttonstyle"
                    }`}
                    onClick={() => checkStatus(item.lr_id)} // Pass the specific lr_id
                  >
                    Approve
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div>
            <h2 className="text-center headcolor text-capitalize">
              Our Vision
            </h2>
            <p className="text-center">Our vision Care for better future</p>
          </div>

          <div>
            <h2 className="text-center headcolor text-capitalize">
              Our values
            </h2>
            <p className="text-center">
              Our values A basic income is inevitable for each and every family
              whether you have a job or may not be able to do a job tommorrow,
              have a low income or may not have it in the future. Each person's
              health care and financial stability is the
              responsibility of society.
            </p>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Ourmission;
