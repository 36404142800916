import React from 'react';
import './Services.css'; // Import your custom styles

import ecommerce from '../images/ecomerce.jpg'

const ECommerce = () => {
  return (
    <div className="e-commerce container">
      <header className="headerser">
        <h1>E-Commerce</h1>
      </header>
      
      <section className="">
      <img src={ecommerce} className='imageheight'/>
      </section>
      <div className='mt-4'>
      
      
      <p>
      Welcome to our E-Commerce platform, where you can explore a wide range of products and services conveniently from the comfort of your home. Our platform provides a seamless shopping experience with advanced search features, secure payment options, and fast delivery services.
    </p>
    <p>
      From electronics and fashion to home goods and beauty products, we offer a diverse selection of items to meet your needs. Discover exclusive deals, track your orders, and enjoy personalized recommendations tailored to your preferences.
    </p>
      </div>

      <section className="features">
        <h2>Features:</h2>
        <ul>
          <li>Extensive product catalog</li>
          <li>Advanced search and filter options</li>
          <li>Secure and convenient payment methods</li>
          <li>Order tracking and customer support</li>
          <li>Personalized product recommendations</li>
        </ul>
      </section>
    </div>
  );
}

export default ECommerce;
