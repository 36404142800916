import React,{ useEffect, useState }  from 'react';
import './ReferencePage.css'; // Create a CSS file for styling if needed
import Header from '../../components/Headermlm/Headermlm';
// import moment from 'moment';
import axios from "axios";

const ReferencePageone = () => {

  const [users, setusers] = useState([]);

  useEffect(() => {
    Userlist()
   }, [])
  const Userlist = async () => {

    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    try {
    const response = await axios.get(
        "https://lunarsenterprises.com:6001/e888/user/list",
        
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );
      setusers(response.data.data)
      // setShowModals(false)
      console.log(response.data.data,"hhh");
  
      // console.log(userstage,"hai");
      
    } catch (error) {
      console.log(error);
    }
  };
    const data = [
        {
          id: 1,
          receiveRequest: 'Request A',
          date: '2024-08-26',
          name: 'John Doe',
          userId: '12345',
        
          phoneNumber: '9876543210',
        },
        {
          id: 2,
      
          date: '2024-08-26',
          name: 'Jane Smith',
          userId: '67890',
        
          phoneNumber: '0123456789',
        },
      ];

  return (
    <div className="">
      <Header/>
      <div className="table-container">
      <h1 className="heading">My Reference</h1>
     
      <div className="table-responsive">
        <table className="custom-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Ref ID</th>
            
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
          {users?.length > 0 ? (

            users.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.u_name}</td>
                <td>{item.u_referal_id}</td>
                
                <td>{item.u_mobile}</td>
              </tr>
            ))
          ):(

            <p className='mt-5 '>No References Availiable</p>
          )}
          
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default ReferencePageone;
