import React from 'react';
import './Services.css';  // Import your custom styles

import agantha from '../images/agantha.jpg'
const AshtangaYogaMeditation = () => {
  return (
    <div className="ashtanga-yoga-meditation container">
      <header className="headerser">
        <h1>ASHTANGA Yoga Meditation</h1>
      </header>
      
      <section className="">
      <img src={agantha} className='imageheight'/>
      </section>
      <div className='mt-4'>
      
      <p>
          Discover the transformative power of ASHTANGA Yoga Meditation, a dynamic and structured practice that connects breath and movement. This meditation technique is rooted in ancient yoga traditions and is designed to bring mental clarity, physical strength, and emotional balance.
        </p>
        <p>
          Our platform guides you through the eight limbs of Ashtanga Yoga, offering step-by-step instructions and tailored meditation sessions. Embrace the journey of self-discovery and inner peace with ASHTANGA Yoga Meditation.
        </p>
        </div>

      <section className="additional-info">
        <h2>Features:</h2>
        <ul>
          <li>Guided meditation sessions</li>
          <li>Comprehensive Ashtanga Yoga tutorials</li>
          <li>Breath and movement synchronization techniques</li>
          <li>Resources for physical and mental well-being</li>
        </ul>
      </section>
    </div>
  );
}

export default AshtangaYogaMeditation;
