import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { IoCall } from 'react-icons/io5'
import { MdEmail } from "react-icons/md";

import logo from "../../pages/images/main_logo.png";

export default function Footer() {
  return (
    <>
      <div className='footer footer-bg '>
       
    

        <div className='container'>

        <div className='row'>

        <div className='col-md-4 align-items-center justify-content-center d-flex'>
        <img src={logo} width="150" height="150" alt="logo" />
        
        
        </div>
        <div className='col-md-4'>

        <h1 className='servfooter mt-4 mb-5'>OUR SERVICES</h1>

        <p className=' hovereffect'>Self Health Management Platform</p>
        <p  className='hovereffect'>ASHTANGA Yoga Meditaion</p>
        
        <p className='hovereffect'> DMIT fingerprints analiyes</p>
        <p className='hovereffect'>Agri Organic</p>
        <p className='hovereffect'>E-Commerce</p>
        <p className='hovereffect'>Priviliage Card</p>
        <a href='/TermsandConditionsforConsumers'>

        <p className='hovereffect'>Terms and Conditions for Consumers
        </p>
        </a>
        <a href='/Privacypolicy'>
        <p className='hovereffect'>Privacy policy </p>
        </a>
     
        
        </div>

        
        <div className='col-md-4'>
        <h1 className='servfooter mt-4 mb-5'>CONTACT INFO</h1>
        <a href="tel:+91 80009 50087">
        <p className='text-black'> <IoCall  color='black' /> India +91 80009 50087</p>
        </a>
       
        <div className="d-flex align-items-center ">
      
        <a  href="mailto:c888@gmail.com">
          <p className="text-black">
          <MdEmail /> c888@gmail.com
          </p>
        </a>
      </div>
        <p className='text-black'> <FaLocationDot   color='black' />  Al khuwair
        Sree Thasmai Foundation 
        Pramayckal Building 
        Pooyappally PO
        Kollam 691537
        Kerala</p> 
        
        </div>
        
        </div>
        
        </div>
      
      </div>
      <div className='copy-right'>
        <div className='copy-right-text'>© copyrights 2024</div>
      </div>
    </>
  )
}
