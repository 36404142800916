import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import "./Headermlm.scss";
import { IoMdClose } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { userContext } from "../../context/Auth";
import { CgProfile } from "react-icons/cg";
import { Dropdown, DropdownButton } from "react-bootstrap";
import imagedemo from "../../pages/images/main_logo.png";
import {
  FaHome,
  FaGift,
  FaInbox,
  FaShareAlt,
  FaBell,
  FaUsers,
  FaCreditCard,
  FaUser,
  FaKey,
  FaSignOutAlt,
  FaQuestionCircle,
} from "react-icons/fa";
import Logo from "../../pages/images/main_logo.png";
import Profile from "../../pages/images/pro.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const { user } = useContext(userContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isRegisterPage =
    location.pathname === "/" ||
    location.pathname === "/Login" ||
    location.pathname === "/forgot" ||
    location.pathname === "/register" ||
    location.pathname === "/otp";
  const [isChecked, setIsChecked] = useState(false);
  const [referralId, setReferralId] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userApiKey, setUserApiKey] = useState("");

  useEffect(() => {
    // Retrieve stored values from localStorage
    const storedReferralId = localStorage.getItem("referral_id");
    const storedUserName = localStorage.getItem("user_name");
    const storedUserId = localStorage.getItem("user_id");
    const storedUserApiKey = localStorage.getItem("user_api_key");

    if (storedReferralId) setReferralId(storedReferralId);
    if (storedUserName) setUserName(storedUserName);
    if (storedUserId) setUserId(storedUserId);
    if (storedUserApiKey) setUserApiKey(storedUserApiKey);

    // Store values in localStorage when user data changes
    if (user && user.data) {
      const { user_referral_id, user_name, user_id, user_api_key } = user.data;
      localStorage.setItem("referral_id", user_referral_id);
      localStorage.setItem("user_name", user_name);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("user_api_key", user_api_key);
      setReferralId(user_referral_id);
      setUserName(user_name);
      setUserId(user_id);
      setUserApiKey(user_api_key);
    }
  }, [user]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleMenuClose = () => {
    setIsChecked(false);
  };

  const logOut = () => {
    try {
      // Clear all items from localStorage
      localStorage.clear();

      // Set any necessary state
      setIsChecked(false);

      // Redirect to the login page
      navigate("/Login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  
   const profileImageUrl = localStorage.getItem("u_profile_pic");
  

  return (
    <>
      <div className="container-fluid header">
        <input
          type="checkbox"
          checked={isChecked}
          id="checkbox"
          onChange={handleCheckboxChange}
          style={{ display: "none" }}
        />
        <FontAwesomeIcon
          icon={faBars}
          className="ham"
          onClick={handleCheckboxChange}
        />

        <NavLink id="logout" className="logout">
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <FontAwesomeIcon
                icon={faUser}
                color="white"
                size="2x"
                className="move"
              />
            }
            className="custom-dropdown"
            variant="light"
          >
            <Dropdown.Item onClick={() => navigate('/my_profile')}>
              <FaUser style={{ marginRight: '8px' }} />
              Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/change-password')}>
              <FaKey style={{ marginRight: '8px' }} />
              Change Password
            </Dropdown.Item>
            <Dropdown.Item href="#/help">
              <FaQuestionCircle style={{ marginRight: '8px' }} />
              Help
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logOut}>
              <FaSignOutAlt style={{ marginRight: '8px' }} />
              Sign Out
            </Dropdown.Item>
          </DropdownButton>
        
        </NavLink>

        <ul className={isChecked ? "show" : ""}>
          <img  src={
            profileImageUrl
              ? `https://lunarsenterprises.com:6001/${profileImageUrl}`
              : imagedemo
          } className="profilestyle" alt="Profile" />
          <IoMdClose
            className="IoMdClose"
            size={30}
            onClick={handleMenuClose}
          />
          <h5 className="name">{userName}</h5>

          <NavLink to="/Dashboard" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaHome size={20} />
              <h5 className="mt-2 h5das ">Dashboard</h5>
            </li>
          </NavLink>
          <NavLink to="/Giverequest" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaGift size={20} />
              <h5 className="mt-2 h5das ">Give Request</h5>
            </li>
          </NavLink>
          <NavLink to="/ReceiveRequest" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaInbox size={20} />
              <h5 className="mt-2 h5das ">Receive Request</h5>
            </li>
          </NavLink>

          <NavLink to="/my_team" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              {" "}
              <FaUsers size={20} />
              <h5 className="mt-2 h5das ">My Team</h5>
            </li>
          </NavLink>
          <NavLink to="/references" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaShareAlt size={20} />
              <h5 className="mt-2 h5das ">My Reference</h5>
            </li>
          </NavLink>
          <NavLink to="/Notification" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaBell size={20} />
              <h5 className="mt-2 h5das ">My Notification</h5>
            </li>
          </NavLink>

          <NavLink to="/PreviliageCard" onClick={handleMenuClose}>
            <li className="dashboardmain d-flex">
              <FaCreditCard size={20} style={{ marginRight: "8px" }} />
              <h5 className="mt-2 h5das">Privilege Card</h5>
            </li>
          </NavLink>
        </ul>
      </div>
    </>
  );
};

export default Header;
