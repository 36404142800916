import {useState} from 'react';
import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate()

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    const passwordReset = (e)=>{
       e.preventDefault()
       navigate("/login")
    }
  return (
    <div className="container-fluid login">
        <form onClick={passwordReset} className='form'>
        <Form.Label htmlFor="inputPassword5">New Password</Form.Label>
        <Form.Control type="password" id="inputPassword5" />
        <Form.Label htmlFor="">Confirm Password</Form.Label>
        <div className="password">
          <Form.Control
            type={passwordVisible ? "text" : "password"}
            id=""
          />
          <div onClick={togglePasswordVisibility}>
            {passwordVisible ? (
              <FaEyeSlash className="closedeye" />
            ) : (
              <FaEye className="openeye" />
            )}
          </div>
        </div>
        
        <button >Enter</button>
        </form>
    </div>
  );
}

export default NewPassword;
