import React from "react";
import "@splidejs/react-splide/css";
import { RiArrowRightSLine } from "react-icons/ri";
import SocialMediaSidebar from "./components/Header/SocialMediaSidebar";
import ScrollArrow from "./components/Header/Scrollarrow";
import EnquiryForm from "./components/Header/EnquiryForm";
import Header from "./components/Header/Header";

import Yoga from "../src/pages/images/yoga.webp";
import agantha from "../src/pages/images/agantha.jpg";

import womwen from "../src/pages/images/womwen.jpg";
import Footer from "./components/Header/Footer";

import banner from './pages/images/c888 banner3m.jpg'
import ServicesSection from "./components/Services/Services";

export default function Landing() {
  return (
    <>
      <head>
        <title>Aviation</title>
      </head>

      <ScrollArrow />
      <Header />
      {/* Hero banner design */}
      <div className="sectio">
       <img src={banner} className="herobanner"/>
       <a
         
          href="/Login"
        >
       <button  className="overlay-button"> Connect to Community</button>
       </a>

      </div>

      {/* {mission} */}

      <div className=" mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="inner-text">
                <h1>MISSION</h1>
                <p style={{ color: "black" }}>
                  It is leading a community to prosperity through <br />
                  self-purification enriched with wealth, health and knowledge.
                 
                </p>
              </div>
              <div className="inner-text">
                <h1>VISION</h1>
                <p style={{ color: "black" }}>
                  
                  Care for better future
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

         {/* manpower  */}
         <div className="section two-">
         <div className="container">
           <div className="row">
             <div className="col-lg-6">
               <div className="inner-two">
                 <h6>What We do</h6>
                 <h3>About us</h3>
                 <p>
                   The world is on a relentless journey towards progress, with
                   innovations and technological advances (AI etc.) rapidly
                   transforming our quality of life. However, amid this surge of
                   progress, a vast majority of people are left without the means
                   to meet even the basic standards of living or enjoy real
                   freedom. Poverty, unemployment, skill development,
                   productivity, monetary control, education system, information
                   technology, women's empowerment, manpower management, revenue
                   management, inflation, and lack of cooperation and friendship,
                   these critical factors pose major challenges in the world
                   system. Here,c888.in plateform emerges as a permanent solution
                   for this.We are committed to empowering individuals healthily,
                   intellectually and financially through our diverse projects
                   and leading communities towards prosperity.
                 </p>
               </div>
             </div>
             <div className="col-lg-6">
               <img
                 src={womwen}
                 className="bg-exp-data"
                 style={{ width: "100%", height: 450 }}
                 alt=""
               />
             </div>
           </div>
         </div>
       </div>

      {/* Avation */}
      <div className="section two-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={Yoga} className="bg-exp-data" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="inner-two">
                <h6 className="mt-4">What We do</h6>
                <h3>Our values</h3>
                <p>
                  A basic income is inevitable for each and every family whether
                  you have a job or may not be able to do a job tommorrow, have
                  a low income or may not have it in the future. Each person's
                  health care and financial stability is the
                  responsibility of society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

   

      {/* count design */}

      

      {/* Avation 
        
          <div className="container">
        <div className="row align-items-center">
        
          <div className="col-lg-6">
            <div className="inner-two">
              <h3 className="mt-3 section-title">Our Services</h3>
              <ul className="service-list">
                <li>
                  <i className="service-icon fas fa-heartbeat"></i>
                  Self Health Management Platform
                </li>
                <li>
                  <i className="service-icon fas fa-spa"></i>
                  ASHTANGA Yoga Meditation
                </li>
                <li>
                  <i className="service-icon fas fa-fingerprint"></i>
                  DMIT Fingerprints Analysis
                </li>
                <li>
                  <i className="service-icon fas fa-seedling"></i>
                  Agri Organic
                </li>
                <li>
                  <i className="service-icon fas fa-shopping-cart"></i>
                  E-Commerce
                </li>
                <li>
                  <i className="service-icon fas fa-id-card"></i>
                  Privilege Card
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        */}

<div>
        <ServicesSection/>
        </div>
   


      
    
    
      <div className="" id="contactus">
        <EnquiryForm />
      </div>

      {/* Our Solutions 

      <div className='section solution-slider'>
        <div className='container'>
          <div className='section-slider'>
            <h6>OUR EXPERTISE</h6>
            <h5>Our Solutions</h5>
            <div className='space5'></div>
            <Splide
                        options={{
                            perPage: 4,
                            breakpoints: {
                                991: {
                                    perPage: 3,
                                },
                                767: {
                                    perPage: 2,
                                },
                                640: {
                                    perPage: 1,
                                },
                            },
                            rewind: true,
                            autoplay: true,
                            interval: 6000,
                            // type: 'fade',
                            // speed: 2200
                        }}
                        aria-label="customList">
                        <SplideSlide>
                            <div className="solution-item solution-one">
                                <img src="/images/soution-icon-1.png" alt="partners_logo"
                                    className="img-responsive" />
                                    <div className='solu-heading-tag'>Our MEP Services</div>
                                    <span>Read More →</span>
                            </div>
                        </SplideSlide>
                     

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-Icon-2.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Integrated MEP <br/>Contracting</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-icon-three.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Pre Fabrication</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-con-four.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Retrofitting Services</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-con-four.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Retrofitting Services</div>
                            </div>
                        </SplideSlide>

                    </Splide>
          </div>
        </div>
      </div>
      */}

      {/* {Fabrication} 

      <div className='section two-left'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='inner-two'>
                <h6>unleashing MEP Revolution with</h6>
                <div className='space5'></div>
                <h3>Pre-Fabrication</h3>
                <div className='space5'></div>
                <p className='paragraph'>Reimagining onsite execution, VKBS revolutionizes MEP services by integrating manufacturing excellence through pre-fabrication for rapid assembly of defect-free modular components.. Our pioneering template-driven prefab techniques integrate innovation with quality and safety to fast-track projects and maximize customer delight.</p>
                <a href='/'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Know More
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src='/images/left.png' className='bg-exp-data' alt='' />
            </div>
          </div>
        </div>
      </div>


 */}

      {/* expertise 
      <div className='section expertise'>
        <div className='container'>
          <h6>Featured projects</h6>
          <div className='row'>
            <div className='col-lg-6'>
              <h3>See our expertise</h3>
            </div>
            <div className='col-lg-6 text-end'>
              <a href='/'
                type="button"
                className="mt-2 btn btn-primary btn-rounded btn-lg">
                View All Projects
              </a>
            </div>
          </div>
          <div className='space10'></div>
          <div className='row'>
          <div className='col-lg-3'>
            <div className='bg-exp bg-exp-data'>
              <h6>Infrastructure</h6>
              <h3>Lorem ipsum dolor sit amet consectetur.</h3>
              <p>Bangalore</p>
              <a href='/'
                type="button"
                className="mt-2 btn btn-primary-two  btn-lg">
                View 
              </a>
            </div>
           
           </div>
           <div className='col-lg-3'>
              <img src='/images/exp1.png' className='bg-exp-data' alt='' />
           </div>
           <div className='col-lg-3'>
           <img src='/images/exp2.png' className='bg-exp-data' alt='' />
           </div>
           <div className='col-lg-3'>
           <img src='/images/exp3.png' className='bg-exp-data' alt='' />
           </div>
          </div>
        </div>
      </div>
*/}

      {/* Delivery 
      <div className='section two-left'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='inner-two'>
                <h6>MEP Excellence With</h6>
                <h3>Integrated Project Delivery</h3>
                <p className='paragraph'>Our MEP services are powered by Centre of Excellence, Project Control Department, stringent 4-Level Monitoring governance, and Transition Task Force, ensuring seamless integration across project lifecycles. This unified alignment of cross-functional expert teams results in optimized outcomes by collectively driving adherence to critical success benchmarks - safety, quality, timely delivery and perfection - across the entirety of Mechanical, Electrical and Plumbing solutions.</p>
                <a href='/'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Know More
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src='/images/grop-vkbs.png' className='bg-exp-data' alt='' />
            </div>
          </div>
        </div>
      </div>
*/}

      {/* Quality 

      <div className='section quality'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
            <img src='/images/pipe.png' className='bg-exp-data' alt='' />
            </div>
            <div className='col-lg-6'>
            <div className='inner-two'>
                <h6>why us</h6>
                
                <h3>Unwavering Commitment to Quality Delivery</h3>
                <p className='paragraph'>With over 65 million square feet of MEP projects executed, 2 million square feet of EPC development encompassing 2000+ residential units, 6,00,000 square feet of specialized fit-outs and delivery of 2,00,000+ square feet A-grade workspaces - VKBS offers unrivaled competencies spanning integrated design-build services, project management and quality-focused sustainable execution of complex mechanical, electrical and plumbing solutions pan-India, making us the industry's most trusted one-stop MEP services provider.</p>
          
                <a href='/contact-us'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Contact Us
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      */}

      {/* client logo 

    
      <div className='section customlist-section'>
                <div className='container'>
                    <h2 className='primary-text'>Trusted by more than <span>30,000</span> companies</h2>
                    <Splide
                        options={{
                            perPage: 5,
                            breakpoints: {
                                991: {
                                    perPage: 3,
                                },
                                767: {
                                    perPage: 2,
                                },
                                640: {
                                    perPage: 1,
                                },
                            },
                            rewind: true,
                            autoplay: true,
                            interval: 6000,
                            // type: 'fade',
                            // speed: 2200
                        }}
                        aria-label="customList">
                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/cisco-logo.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/net-app-logo.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/sify.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/hp.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/ibm.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/hp.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>
                     
                      
                     
                         
                    </Splide>
                </div>
     </div> 


*/}
<Footer/>
    </>
  );
}
