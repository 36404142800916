import React from 'react';
import agantha from '../../pages/images/agantha.jpg';
import '../Services/Services.css';

const ServicesSection = () => {
  return (
    <div className="">
      <div className="container">
        <div className=" align-items-center justify-content-center d-flex">
          
          <div className="col-lg-10">
            <div className="inner-two">
              <h3 className="mt-3 section-title">Our Services</h3>
              <ul className="service-list">
                <li>
                  <i className="service-icon fas fa-heartbeat"></i>
                  <div className="service-content">
                    <h4>Self Health Management Platform</h4>
                    <p>Empower yourself with tools to manage your health, track wellness, and stay informed about your well-being.</p>
                  </div>
                </li>
                <li>
                  <i className="service-icon fas fa-spa"></i>
                  <div className="service-content">
                    <h4>ASHTANGA Yoga Meditation</h4>
                    <p>Achieve mental and physical balance through our structured ASHTANGA Yoga sessions and meditation techniques.</p>
                  </div>
                </li>
                <li>
                  <i className="service-icon fas fa-fingerprint"></i>
                  <div className="service-content">
                    <h4>DMIT Fingerprints Analysis</h4>
                    <p>Discover your innate talents and strengths with our scientific fingerprint analysis for personal and professional growth.</p>
                  </div>
                </li>
                <li>
                  <i className="service-icon fas fa-seedling"></i>
                  <div className="service-content">
                    <h4>Agri Organic</h4>
                    <p>Promote sustainable farming practices with our organic agriculture solutions, designed to boost productivity and health.</p>
                  </div>
                </li>
                <li>
                  <i className="service-icon fas fa-shopping-cart"></i>
                  <div className="service-content">
                    <h4>E-Commerce</h4>
                    <p>Explore a wide range of products and services tailored to your needs through our user-friendly e-commerce platform.</p>
                  </div>
                </li>
                <li>
                  <i className="service-icon fas fa-id-card"></i>
                  <div className="service-content">
                    <h4>Privilege Card</h4>
                    <p>Unlock exclusive benefits and discounts with our Privilege Card, designed for our valued customers.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
