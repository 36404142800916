import React, { useEffect, useState } from "react";
import './NotificationPage.css'; // Create a CSS file for styling if needed
import Header from '../../components/Headermlm/Headermlm';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationPage = () => {
  const [listreq, setlistreq] = useState([]);

  useEffect(() => {
    Requestlist();
  }, []);

  const Requestlist = async () => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      if (user_token && user_id) {
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/user/notification", {},
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id: user_id,
            },
          }
        );

        // Check if the response data exists and is an array
        if (response.data && Array.isArray(response.data.data)) {
          setlistreq(response.data.data);
        } else {
          setlistreq([]);
        }
      } else {
        console.log("User not authenticated.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notificationbutton = async (lr_master_u_id, lr_id) => {
    try {
      const user_token = localStorage.getItem("user_token");

      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/levelup/request/approve",
        { lr_id: lr_id },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: lr_master_u_id,
          },
        }
      );

      const message = response.data.message;
      if (response.data.result === true) {
        toast.success(message);
        Requestlist(); // Refresh notifications after successful approval
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the request.");
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="notification-container">
        <h1 className="heading">Notifications</h1>
        <ul className="notification-list">
          {listreq?.length > 0 ? (
            listreq.map((notification) => (
              <li key={notification.id} className="notification-item">
                <div className="notification-content">
                  <span className="username">{notification.u_name}</span>
                  <span className="userid">(Ref Id {notification.u_referal_id})</span>
                  <span className="message">{" sent a request to level up"}</span>
                  <span className="date">{notification.date}</span>
                </div>
                <button
                  className="accept-button"
                  onClick={() => notificationbutton(notification.lr_master_u_id, notification.lr_id)}
                >
                  Accept
                </button>
              </li>
            ))
          ) : (
            <p>No Notifications Available</p>
          )}
        </ul>
      </div>
    </>
  );
};

export default NotificationPage;
