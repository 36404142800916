import React from 'react';
import './Services.css'; // Import your custom styles

import dmt from '../images/dmt.jpg'


const DmitFingerprintsAnalysis = () => {
  return (
    <div className="dmit-fingerprints-analysis container">
      <header className="headerser">
        <h1>DMIT Fingerprints Analysis</h1>
      </header>
      
      <section className="">
      <img src={dmt} className='imageheight'/>
      </section>
      <div className='mt-4'>
      
      
      <p>
      DMIT (Dermatoglyphics Multiple Intelligence Test) Fingerprints Analysis is a scientific method to understand an individual's innate potential and personality traits through the analysis of fingerprint patterns. This technique is based on the study of dermatoglyphics, the branch of science that studies the patterns of skin ridges on the fingers.
    </p>
    <p>
      Our platform offers a detailed DMIT analysis, helping you uncover your natural talents, learning styles, and areas of intelligence. Whether you're a student, professional, or parent, DMIT can provide valuable insights to guide your personal and professional development.
    </p>
    </div>

      <section className="additional-info">
        <h2>Features:</h2>
        <ul>
          <li>Comprehensive fingerprint analysis</li>
          <li>Personalized reports on multiple intelligences</li>
          <li>Insights into learning styles and potential</li>
          <li>Guidance for career and personal development</li>
        </ul>
      </section>
    </div>
  );
}

export default DmitFingerprintsAnalysis;
