import React from 'react';
import './Forgot.scss';
import Form from "react-bootstrap/Form";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

const forgot = () => {

    const notify = (e) => {
        e.preventDefault()
       
    }

  return (
    <div className="container-fluid forget">
        <form className='form' onClick={notify}>
            <h2>Forgot Password</h2>
        <Form.Label htmlFor="email">Enter your registered id</Form.Label>
        <Form.Control type="email" id='email' />
        <NavLink to="/otp">
        <button >Send OTP</button>
        </NavLink>
        </form>
    </div>
  );
}

export default forgot;
