import React, { useEffect, useState } from "react";
import "../Giverequest/Giverequest.css"; // Add your custom styles here
import Header from "../../components/Headermlm/Headermlm";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TableComponent = () => {
  const [listreq, setlistreq] = useState([]);

  useEffect(() => {
    Requestlist();
  }, []);

  const Requestlist = async () => {
    try {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      if (user_token && user_id) {
        const response = await axios.get(
          "https://lunarsenterprises.com:6001/e888/user/levelup/request/list",
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id: user_id,
            },
          }
        );

        setlistreq(response.data.data);
      } else {
        console.log("User not authenticated.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkStatus = async (status, u_id) => {
    if (status === "approved") {
      toast.error("This request has already been approved.");
      return;
    }

    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");

    try {
      const master = { master_user_id: u_id };

      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/levelup/request/send",
        master,
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      );

      const { message } = response.data;
      toast.success(message);

      // Refresh the list after a successful request
      Requestlist();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the request.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="table-container">
        <h1 className="heading">Give Request</h1>
        
        <div className="table-responsive">
          <table className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Ref ID</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Phone Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {listreq.map((item, index) => {
                const isApproved = item.lr_status === "approved";
                const isFirstButtonEnabled = index === 0 || listreq[index - 1].lr_status === "approved";
                const isButtonEnabled = index === 0 || (index > 0 && listreq[index - 1].lr_status === "approved");

                let buttonText = "";
                let buttonStyle = {
                  backgroundColor: isButtonEnabled ? "" : "aqua",
                  cursor: isButtonEnabled ? "pointer" : "not-allowed",
                };

                if (item.lr_status === "pending") {
                  buttonText = "Request";
                  buttonStyle.backgroundColor = isButtonEnabled ? "green" : "blue";
                } else if (item.lr_status === "active") {
                  buttonText = "Waiting";
                  buttonStyle.backgroundColor = isButtonEnabled ? "grey" : "aqua";
                } else if (item.lr_status === "approved") {
                  buttonText = "Approved";
                  buttonStyle.backgroundColor = isButtonEnabled ? "black" : "aqua";
                }

                return (
                  <tr key={item.u_id}>
                    <td>{index + 1}</td>
                    <td>{item.u_name}</td>
                    <td>{item.u_referal_id}</td>
                    <td>{item.lr_amount}</td>
                    <td>
                      <button
                        className={`status-button ${isApproved ? "complete" : "pending"}`}
                      >
                        {isApproved ? "Completed" : "Pending"}
                      </button>
                    </td>
                    <td>{item.u_mobile}</td>
                    <td>
                      <button
                        onClick={() => checkStatus(item.lr_status, item.u_id)}
                        className="status-buttongive"
                        disabled={!isButtonEnabled}
                        style={buttonStyle}
                      >
                        {buttonText}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableComponent;
