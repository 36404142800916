import React from 'react';
import Form from "react-bootstrap/Form";
import { NavLink } from 'react-router-dom';


const Otp = () => {
  return (
    
       <div className="container-fluid login">
        <form action="" className='form'>
        <Form.Label htmlFor="otp">Enter Your OTP</Form.Label>
        <Form.Control type="text" id='otp' />
        <NavLink to="/new_password">
        <button >Enter</button>
        </NavLink>
        </form>
    </div>
  );
}

export default Otp;
