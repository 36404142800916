import React from 'react';
import './Postlogin.scss';
import { NavLink,useLocation } from "react-router-dom";
import moment from 'moment';
import Logo from '../images/main_logo.png'

const Postlogin = () => {
  const location = useLocation();
  const input = location.state?.input || "";
  const referral_id = location.state?.referral_id || "Demo";
  console.log(input,moment().format("DD-MMM-YYYY hh:mm A"))
  console.log(referral_id,'sadfdsdsv');
  localStorage.setItem("referral_id", referral_id);

  console.log(referral_id, 'referral_id');


  return (
    <div className='container-fluid registerpost' id='register'>
      <div className="log-details">
        <div className="logo">
          <img src={Logo} alt="logo" className='mb-2' />
          <h4>Welcome</h4>
           <h4>you are now part of C888 family</h4>
        </div>
        <h4><span>Congratulations! Your Account Has Been Successfully Created</span></h4>

        <div className="register-details">
        <div className="sub-details">
          <h5>Name:</h5>
          <h5>{input.name}</h5>
        </div>
        <div className="sub-details">
          <h5>Referal Id:</h5>
          <h5>{referral_id}</h5>
        </div>
        <div className="sub-details">
          <h5>Password:</h5>
          <h5>{input.password}</h5>
        </div>
       
        <div className="sub-details">
          <h5>Date of Joining:</h5>
          <h5>{moment().format("DD-MMM-YYYY hh:mm A")}</h5>
        </div>
        <div className="sub-details">
          <h5 className='email'>Email Id:{input.email}</h5>
          
        </div>
      </div>
      <NavLink to="/Login">
      <button>Log In</button>
      </NavLink>
      </div>
    </div>
  );
}

export default Postlogin;
