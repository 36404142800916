import React from 'react';
import './Error.css'; // Import your custom styles
import errorImage from '../images/404.jpg'; // Replace with the path to your error image

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="error-container">
        <img src={errorImage} alt="404 Error" className="error-image" />
        <h1 className="error-title">404 - Page Not Found</h1>
        <p className="error-message">Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
}

export default NotFound;
