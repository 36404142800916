import React from 'react';
import './Services.css'; // Import your custom styles

import ario from '../images/agro.jpg'

const AgriOrganic = () => {
  return (
    <div className="agri-organic container">
      <header className="headerser">
        <h1>Agri Organic</h1>
      </header>
      
      <section className="">
      <img src={ario} className='imageheight'/>
      </section>
      <div className='mt-4'>
      <p>
      Agri Organic focuses on sustainable and eco-friendly farming practices that emphasize the health of the soil, plants, animals, and humans. Our approach is rooted in organic agriculture, which avoids synthetic chemicals and emphasizes natural processes and cycles.
    </p>
    <p>
      Our platform offers resources and guidance for farmers, gardeners, and consumers interested in organic farming. From organic fertilizers and pest control methods to crop rotation and soil health management, Agri Organic provides comprehensive information to help you grow healthy, sustainable produce.
    </p>
      
      </div>

      <section className="additional-info">
        <h2>Features:</h2>
        <ul>
          <li>Guidance on organic farming techniques</li>
          <li>Information on natural fertilizers and pest control</li>
          <li>Resources for soil health and crop rotation</li>
          <li>Community support and best practices for sustainable farming</li>
        </ul>
      </section>
    </div>
  );
}

export default AgriOrganic;
