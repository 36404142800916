import React, { useEffect, useState } from "react";
import Header from "../../components/Headermlm/Headermlm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from '../../pages/images/main_logo.png';
import imagedemo from "../images/404.jpg";

const CardUI = () => {
  const [userData, setUserData] = useState({
    u_name: "",
    u_gender: "",
    u_email: "",
    u_mobile: "",
    u_payment_type: "",
    u_state: "",
    u_district: "",
    u_address: "",
    u_referal_id: "",
    u_profile_pic: "",
    Giveamount: "",
    Receiveamount: "",
    u_joining_date: "",
    bank: [
      {
        b_name: "",
        acc_name: "",
        acc_number: "",
        ifsc_code: "",
        br_name: "",
      },
    ],
    master: [
      {
        u_name: "",
        u_mobile: "",
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const user_token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/user/view",
          { user_id },
          {
            headers: {
              Authorization: `Bearer ${user_token}`,
              user_id,
            },
          }
        );
        console.log("API Response:", response.data);
        const user = response.data.data[0];
        setUserData(user);
      } catch (error) {
        toast.error("An error occurred while fetching data.");
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const Download = async () => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6001/e888/user/privilegecard",
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id,
          },
        }
      );

      if (response.data.result) {
        const filePath = response.data.filepath;
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "privilegecard.pdf"; // This will trigger the download with the specified file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Failed to download the privilege card. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while downloading the privilege card.", error);
      toast.error("Failed to download the privilege card. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <header className="headerser">
        <h1>Privilege Card</h1>
      </header>
      <div style={styles.outer}>
        <div style={styles.outerContainer} className="m-1">
          <div style={styles.cardContainer}>
            <div style={styles.chipContainer}>
              <img src={logo} alt="Chip" style={styles.chip} />
            </div>
            <div style={styles.cardNumber}>
              <h2>{userData.u_referal_id}</h2>
            </div>
            <div style={styles.cardDetails}>
              <div>
                <p style={styles.label}>Cardholder Name</p>
                <h5>{userData.u_name}</h5>
              </div>
              <div>
                <p style={styles.label}>Valid To</p>
                <h4>{userData.u_joining_date}</h4>
              </div>
            </div>
            <div style={styles.profileContainer}>
              <img
                src={
                  userData.u_profile_pic
                    ? `https://lunarsenterprises.com:6001/${userData.u_profile_pic}`
                    : imagedemo
                }
                alt="Profile"
                style={styles.profilePic}
              />
            </div>
          </div>
        </div>
        <div className="justify-content-center d-flex">
          <button onClick={Download} style={styles.downloadButton}>
            Download
          </button>
        </div>
      </div>
    </>
  );
};

const styles = {
  outer: {
    marginTop: "50px",
    height: "160vh",
  },
  downloadButton: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#31588a",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    backgroundColor: "#31588a",
    width: "100%",
    maxWidth: "400px",
    height: "220px",
    borderRadius: "20px",
    padding: "20px",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box",
  },
  chipContainer: {
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  chip: {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
  },
  cardNumber: {
    marginTop: "60px",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "2px",
  },
  cardDetails: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    padding: "0 10px",
  },
  label: {
    fontSize: "12px",
    marginBottom: "5px",
    textTransform: "uppercase",
    opacity: 0.8,
  },
  profileContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  profilePic: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },
  "@media (max-width: 480px)": {
    cardContainer: {
      padding: "15px",
      height: "200px",
    },
    chip: {
      width: "30px",
      height: "20px",
    },
    cardNumber: {
      fontSize: "20px",
      marginTop: "50px",
    },
    cardDetails: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "15px",
      padding: "0",
    },
    label: {
      fontSize: "10px",
    },
    profileContainer: {
      top: "10px",
      right: "10px",
      width: "50px",
      height: "50px",
    },
  },
};

export default CardUI;
