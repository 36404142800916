// import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useLocation } from "react-router-dom";
// import "./Myteam.scss";
// import axios from "axios";
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import UserLevels from "../../components/Userlevel/UserLevels";

// const Myprofile = () => {
//   const location = useLocation();
//   const [team, setTeam] = useState([]);
//   const data = location.state?.data || {};
//   const [fetchId, setFetchId] = useState(data.user_id);
//   const [arrowVisible, setArrowVisible] = useState(false);
//   const [backId, setBackId] = useState([]);
//   const [filterMember, setFilterMember] = useState("All");
//   const [active_id, setactive_id] = useState([]);
//   const [Master, setmaster] = useState();

//   const smartBoys = async (id, filter) => {
//     console.log(id, filter);
//     const user_token = localStorage.getItem("user_token");
//     const user_id = localStorage.getItem("user_id");

//     try {
//       const response = await axios.get(
//         "https://lunarsenterprises.com:6001/e888/user/list",
//         {
//           headers: {
//             Authorization: `Bearer ${user_token}`,
//             user_id: user_id,
//           },
//         }
//       );

//       if (Array.isArray(response.data.data)) {
//         const inactiveIds = response.data.data
//           .filter((item) => item.u_status === "inactive")
//           .map((item) => item.u_id);

//         setactive_id(inactiveIds);

//         setTeam(response.data.data);

//         console.log(inactiveIds, "Inactive User IDs");

//         const masterIds = response.data.data
//           .map((item) => item.u_master_id)
//           .filter((id) => id !== undefined && id !== null);

//         if (masterIds.length > 0) {
//           setmaster(masterIds[0]);
//         } else {
//           console.log("No valid master IDs found.");
//         }

//         console.log(masterIds[0], "Master ID at Zeroth Index");
//       } else {
//         setTeam([]);
//         console.log("Data format is incorrect");
//       }
//     } catch (error) {
//       console.log(error);
//       setTeam([]);
//     }
//   };

//   const upDown = () => {
//     console.log("welcome to manus multi speciality hospital");
//     setBackId((prevBackId) => {
//       const updatedBackId = [...prevBackId];
//       const lastId = updatedBackId.pop();
//       if (updatedBackId.length === 0) {
//         setArrowVisible(false);
//       }
//       smartBoys(lastId, filterMember);
//       return updatedBackId;
//     });
//   };

//   const functionRecall = (member) => {
//     console.log(member.u_master_id);
//     setFetchId(member.u_id);
//     setArrowVisible(true);
//     setBackId((prevBackId) => [...prevBackId, member.u_master_id]);
//   };

//   useEffect(() => {
//     smartBoys(fetchId, filterMember);
//   }, [fetchId, filterMember]);

//   const handleFilter = (e) => {
//     const newFilter = e.target.value;
//     setFilterMember(newFilter);
//     smartBoys(fetchId, newFilter);
//     console.log(newFilter);
//   };

//   const user_token = localStorage.getItem("user_token");
//   const user_id = localStorage.getItem("user_id");
//   const checkStatus = async () => {
//     try {
//       const master = {
//         master_user_id: Master,
//       };
//       const response = await axios.post(
//         "https://lunarsenterprises.com:6001/e888/user/levelup/request/send",
//         master,
//         {
//           headers: {
//             Authorization: `Bearer ${user_token}`,
//             user_id: user_id,
//           },
//         }
//       );
//       const { message } = response.data;
//       console.log(response.data);
//       toast.success(message);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const Activationbtn = async (id) => {
//     try {
//       const user_token = localStorage.getItem("user_token");
//       const user_id = localStorage.getItem("user_id");

//       const requestData = {
//         u_id: id,
//       };

//       const response = await axios.post(
//         "https://lunarsenterprises.com:6001/e888/user/activate",
//         requestData,
//         {
//           headers: {
//             Authorization: `Bearer ${user_token}`,
//             user_id: user_id,
//           },
//         }
//       );

//       const { message } = response.data;
//       console.log(response.data);
//       toast.success(message);

//       await smartBoys();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // Calculate progress percentage
//   const activeMembers = team.filter((member) => member.u_status === "active");
//   const progressPercentage =
//     team.length > 0 ? (activeMembers.length / team.length) * 100 : 0;

//   return (
//     <>
//       <div className="container-fluid myprofile py-5">
//         <ToastContainer />
//         {arrowVisible && backId.length > 0 && (
//           <IoMdArrowRoundBack
//             color="white"
//             size={30}
//             style={{
//               position: "fixed",
//               left: "30px",
//               top: "100px",
//               cursor: "pointer",
//             }}
//             onClick={upDown}
//           />
//         )}

//         <h4 className="text-white">My Team</h4>
//         <UserLevels user_id={user_id} />

//         <div className="table-responsive mt-4 py-4 px-4">
//           <div>
//             <table className="table table-light table-hover shadow">
//               <thead>
//                 <tr>
//                   <th scope="col" style={{ whiteSpace: "nowrap" }}>
//                     Sl no
//                   </th>
//                   <th scope="col">Name</th>
//                   <th scope="col">Phone Number</th>
//                   <th scope="col" style={{ whiteSpace: "nowrap" }}>
//                     User Id
//                   </th>
//                   <th scope="col">Refferal Id</th>
//                   <th scope="col">Status</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {Array.isArray(team) && team.length > 0 ? (
//                   team.map((member, index) => (
//                     <tr key={index}>
//                       <th scope="row">{index + 1}</th>
//                       <td>{member.u_name}</td>
//                       <td>{member.u_mobile}</td>
//                       <td>{member.u_referal_id}</td>
//                       <td>{member.master_referral_id}</td>
//                       <td>
//                         <div
//                           className={`mb-1 mt-1 ${
//                             member.u_status === "active"
//                               ? "btn-success"
//                               : "btn-danger"
//                           }`}
//                           onClick={() => Activationbtn(member.u_id)}
//                         >
//                           {member.u_status}
//                         </div>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="7" className="text-center">
//                       No data available
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Myprofile;

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import "./Myteam.scss";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import UserLevels from "../../components/Userlevel/UserLevels";
import StageChart from "../Stages/Stages";
import Header from "../../components/Headermlm/Headermlm";

const Myprofile = () => {
  const location = useLocation();
  const [team, setTeam] = useState([]);
  const data = location.state?.data || {};
  const [fetchId, setFetchId] = useState(data.user_id);
  const [arrowVisible, setArrowVisible] = useState(false);
  const [backId, setBackId] = useState([]);
  const [filterMember, setFilterMember] = useState("All");
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const smartBoys = async (id, filter) => {
    console.log(id, filter, "hello mone");
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:6001/e888/user/list",
        // {
        //   u_level: filter,
        // },
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: id,
          },
        }
      );

      if (Array.isArray(response.data.data)) {
        setTeam(response.data.data);
        //localStorage.setItem(response.data.data.u_level);

        console.log(response.data.data);
      } else {
        setTeam([]);
        console.log("Data format is incorrect");
      }
    } catch (error) {
      console.log(error);
      setTeam([]);
    }
  };

  const upDown = () => {
    console.log("welcome to manus multi speciality hospital");
    setBackId((prevBackId) => {
      const updatedBackId = [...prevBackId];
      const lastId = updatedBackId.pop();
      if (updatedBackId.length === 0) {
        setArrowVisible(false);
      }
      smartBoys(lastId, filterMember);
      return updatedBackId;
    });
  };

  const functionRecall = (member) => {
    console.log(member.u_master_id);
    setFetchId(member.u_id);
    setArrowVisible(true);
    setBackId((prevBackId) => [...prevBackId, member.u_master_id]);
  };

  useEffect(() => {
    smartBoys(fetchId, filterMember);
  }, [fetchId, filterMember]);

  const handleFilter = (e) => {
    const newFilter = e.target.value;
    setFilterMember(newFilter);
    smartBoys(fetchId, newFilter);
    console.log(newFilter);
  };

  return (
   <>
   <Header/>  
     <div className="container-fluid myprofile py-5">
      {arrowVisible && backId.length > 0 && (
        <IoMdArrowRoundBack
          color="white"
          size={30}
          style={{
            position: "fixed",
            left: "30px",
            top: "100px",
            cursor: "pointer",
          }}
          onClick={upDown}
        />
      )}

      
       { /** 
        
        <h4 className="text-white">My Team</h4>

      <UserLevels user_id={user_id} />
      <div className="select mt-3">
        <select
          className="form-select shadow"
          name=""
          id=""
          onChange={handleFilter}
          value={filterMember}
        >
          <option value="All">All</option>
          <option value="1">Level 1</option>
          <option value="2">Level 2</option>
          <option value="3">Level 3</option>
          <option value="4">Level 4</option>
        </select>
      </div>
        <div className="table-responsive mt-4 py-4 px-4">
        <table className="table table-light table-hover shadow">
          <thead>
            <tr>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Sl no
              </th>
              <th scope="col">Name</th>
              <th scope="col">Phone Number</th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                User Id
              </th>
              <th scope="col">Master Id</th>
              
              <th scope="col">Status</th>
              <th scope="col">Level </th>
              <th scope="col">View</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(team) && team.length > 0 ? (
              team.map((member, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{member.u_name}</td>
                  <td>{member.u_mobile}</td>
                  <td>{member.u_referal_id}</td>
                  <td>{member.u_master_id}</td>
                 
                  <td>{member.u_status}</td>
                  <td>{member.u_level}</td>
                  <td>
                    <span
                      className="btn btn-primary"
                      onClick={() => functionRecall(member)}
                    >
                      View
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>*/}
      
      <StageChart/>
    </div>
    </>

  );
};

export default Myprofile;
