import React from 'react';
import './Services.css'; // Import your custom styles


import wushuser from '../images/wushuser.jpg'
const MartialArts = () => {
  return (
    <div className="martial-arts container">
      <header className="headerser">
        <h1>Martial Arts: Wushu, Taichi</h1>
      </header>
      
      <section className="">
      <img src={wushuser} className='imageheight'/>
      </section>
      <div className='mt-4'>
      
      <p>
      Dive into the world of Martial Arts with Wushu and Taichi, ancient practices that blend physical prowess with mental discipline. Wushu, known for its dynamic movements and combat techniques, and Taichi, revered for its graceful and meditative forms, offer a balanced approach to health and self-defense.
    </p>
    <p>
      Our platform provides comprehensive training modules for both Wushu and Taichi, catering to beginners and advanced practitioners alike. Enhance your physical strength, improve your flexibility, and achieve inner calm through the disciplined practice of these martial arts.
    </p>
    </div>

      <section className="additional-info">
        <h2>Features:</h2>
        <ul>
          <li>Step-by-step Wushu training tutorials</li>
          <li>Guided Taichi sessions for mental and physical well-being</li>
          <li>Focus on balance, coordination, and agility</li>
          <li>History and philosophy of Wushu and Taichi</li>
        </ul>
      </section>
    </div>
  );
}

export default MartialArts;
