import React, { useEffect, useState } from 'react';
import '../dashboard/dashboard.css';
import ProfileImage from '../../pages/images/pro.webp'; // Replace with the actual path to your profile image
import Header from '../../components/Headermlm/Headermlm';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imagedemo from "../images/main_logo.png";

const Dashboard = () => {
  const [userData, setUserData] = useState({
    u_name: "",
    u_gender: "",
    u_email: "",
    u_mobile: "",
    u_payment_type: "",
    u_state: "",
    u_district: "",
    u_address: "",
    u_referal_id: "",
    u_profile_pic: "",
    Giveamount: "",
    Receiveamount: "",
    bank: [
      {
        b_name: "",
        acc_name: "",
        acc_number: "",
        ifsc_code: "",
        br_name: "",
      },
    ],
    master: [
      {
        u_name: "",
        u_mobile: "",
      },
    ],
  });

  useEffect(() => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");

    axios
      .post(
        "https://lunarsenterprises.com:6001/e888/user/view",
        { user_id: user_id }, // This should be in the body of the POST request
        {
          headers: {
            Authorization: `Bearer ${user_token}`,
            user_id: user_id,
          },
        }
      )
      .then((response) => {
        const user = response.data.data[0];
        setUserData(user);

        // Store the profile image URL in localStorage
        if (user.u_profile_pic) {
          localStorage.setItem("u_profile_pic", user.u_profile_pic);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        console.error(error);
      });
  }, []);

  const copyToClipboard = () => {
    const referralLink = `https://www.c888.in/register/?referral_id=${userData.u_referal_id}`;
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        toast.success("Referral link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy the link.");
        console.error("Clipboard error:", error);
      });
  };

  // Retrieve the profile image from localStorage
  const profileImageUrl = localStorage.getItem("u_profile_pic");

  return (
    <>
      <Header />
      <ToastContainer />
      <div className='container profile-main mt-5'>
        <h2 className="profile-greeting text-center">Welcome {userData.u_name}</h2>
        
        <div className="profile-container">
          <img
            src={
              profileImageUrl
                ? `https://lunarsenterprises.com:6001/${profileImageUrl}`
                : imagedemo
            }
            alt="Profile"
            className='profile-image'
          />
          
          <h2 className="profile-name">{userData.u_name}</h2>
          <p className="referral-id">Referral ID: {userData.u_referal_id}</p>
          <div className="profile-stats">
            <p className="profile-stat">Total Give Requests: <span>{userData.Giveamount}</span></p>
            <p className="profile-stattotal">Total Received Requests: <span>{userData.Receiveamount}</span></p>
            <p className="profile-stat mt-5">Your Team Lead: <span>{userData.master[0]?.u_name}</span></p>
            <p className="profile-stat">Contact Number: <span>{userData.master[0]?.u_mobile}</span></p>
          </div>

          <button onClick={copyToClipboard} className="btn btn-primary mt-2">
            Share Referral Link
          </button>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
